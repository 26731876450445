<template>
  <nav ref="nav"
       :class="{'fixed' : fixed}">
    <div class="pc">
      <div class="logo">
        <router-link to="/">
          <img src="~@/assets/images/logo-white-text.png"
               @error="handleError"
               alt="logo">
        </router-link>
      </div>
      <div class="nav">
        <cq-menu :data="navigation"
                 :router-link="routerLink"
                 :on-login="onLogin"
                 class="nav-list"/>
      </div>
      <!-- <div class="login">
        <template v-if="false">
          <div class="user-center" @click="onLogin"
            @keydown="onLogin">
          <span>登陆</span>
        </div>
          <span class="line"></span>
          <span>注册</span>
        </template>
        <template v-else>
          <div class="user-center" @click="onLogin"
            @keydown="onLogin">
            <span style="color: #fff;">个人中心</span>
          </div>
        </template>
      </div> -->
    </div>
    <div class="mobile">
      <div class="container">
        <div class="logo">
          <router-link to="/">
            <img src="~@/assets/images/logo-white-text.png"
                 @error="handleError"
                 alt="logo">
          </router-link>
        </div>
        <button class="cq-btn cq-btn--s1n1"
                id="nav-btn--mobile">
          <span class="cq-btn--line"></span>
        </button>
      </div>
      <div class="cq-popup cq-popup--left"
           id="nav-popup--mobile">
        <div class="cq-popup--content">
          <!-- 关闭按钮 -->
          <button class="cq-btn cq-btn--text"
                  id="nav-btn--mobile_close">
            <div class="cq-btn--line"></div>
          </button>
          <!-- 手机端导航 -->
          <cq-menu :data="navigation"
                   mode="vertical"
                   :router-link="routerLink"
                   :on-login="onLogin"
                   class="nav-list--mobile"/>
        </div>
      </div>
    </div>
    <login-dialog ref="loginDialog" :type="type"/>
  </nav>
</template>

<script>
import { mapState } from 'vuex';
import CqMenu from '@/components/CQuery/Menu/index.vue';
import LoginDialog from '@/components/Front/LoginDialog/index.vue';
import loadFailImg from '@/assets/images/load-fail.png';
import { getToken } from '@/utils/auth';

export default {
  isNotLogin: true,
  name: 'FrontNavigation',
  components: {
    CqMenu,
    LoginDialog,
  },
  data() {
    return {
      type: 'normal',
      fixed: false,
      loadFail: loadFailImg,
      popup: null,
    };
  },
  computed: mapState({
    navigation: (state) => state.component.navigation,
  }),
  methods: {
    // 加载失败图片
    handleError(e) {
      /* eslint-disable */
      e.target.src = this.loadFail;
    },
    init() {
      const navEl = this.$refs.nav;
      window.addEventListener('scroll', () => {
        const {scrollTop} = document.documentElement;
        if (scrollTop > navEl.offsetHeight) {
          this.fixed = true;
        } else {
          this.fixed = false;
        }
      });
    },
    initMobileNav() {
      let navBtn = null;
      const navPopup = window.cquery.Popup.create({
        element: '#nav-popup--mobile',
        onClose() {
          if (navBtn) {
            navBtn.el.classList.remove('click');
          }
        },
      });

      // console.log(navPopup)

      navBtn = window.cquery.Button.create({
        element: '#nav-btn--mobile',
        onClick() {
          navPopup.show();
        },
      });
      window.cquery.Button.create({
        element: '#nav-btn--mobile_close',
        onClick() {
          navPopup.close();
        },
      });
      this.popup = navPopup
    },
    onLogin() {
      this.popup.close();
      if (getToken()) {
        if (this.$router.history.current.path !== "/user/comment") this.$router.push({path: '/user/comment'});
      } else {
        const {loginDialog} = this.$refs;
        loginDialog.showLoginDialog();
        this.isNotLogin = false;
      }
    },
    routerLink(nav) {
      let pageName = '';
      // if (index % 3 === 0) {
      //   pageName = 'news';
      // } else if (index % 2 === 0) {
      //   pageName = 'product';
      // } else {
      //   pageName = 'article';
      // }
      switch (nav.sectionType) {
        case '0':
          pageName = 'news';
          break;
        case '1':
          pageName = 'article';
          break;
        case '2':
          pageName = 'product';
          break;
        case '3':
          pageName = 'user/comment';
          break;
        default:
          pageName = 'news';
          break;
      }
      let link = {path: `/${pageName}/${nav.id}`};
      if (nav.parent) {
        link = {
          path: `/${pageName}/${nav.parent.id}`,
          query: {
            children: nav.id,
          },
        };
      }
      return link;
    },
    // 个人中心
    setPersonalCenter() {
      if (this.navigation[this.navigation.length - 1].name === '个人中心') {
        return
      } else {
        this.navigation.push(
          {
            children: [],
            id: '',
            name: '个人中心',
            pid: '0',
            sectionType: '3',
          }
        )
      }
    }
  },
  watch: {
    "navigation"(newVal, oldVal) {
      this.setPersonalCenter()
    }
  },
  mounted() {
    this.init();
    this.initMobileNav();
    // this.setPersonalCenter();
  },
};
</script>

<style lang='scss' scoped>
nav {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  color: #fff;
  background-color: transparent;
  transition: all 0.4s ease;

  &.fixed {
    background-color: var(--theme-color);
  }

  .pc {
    display: flex;
    justify-content: space-between;
    padding: 10px 20px;

    @include media-breakpoint-down(lg) {
      display: none;
    }
  }

  .mobile {
    @include media-breakpoint-up(lg) {
      display: none;
    }

    .container {
      padding: 7px 15px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  .logo {
    width: 7%;
    min-width: 80px;

    img {
      max-width: 100%;
    }
  }

  .nav {
    flex: 1;
    display: inline-flex;
    justify-content: center;
    padding: 10px 10px 0;

    .nav-list {
      --color-text: #fff;
      flex: 1;

      ::v-deep {
        .cq-menu--list {
          justify-content: space-around;
        }

        .cq-menu--title {
          font-size: 16px;
        }
      }
    }
  }

  .login {
    padding: 21px 0 0;

    span {
      font-size: 16px;
      font-weight: bold;
    }

    .line {
      border-right: 1px solid #fff;
      margin: 0 15px;
    }
  }

  .user-center {
    cursor: pointer;
  }

  #nav-popup--mobile {
    --width-popup: 180px;
    --height-popup: 100%;
  }
}
</style>
