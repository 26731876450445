<template>
  <div class="login-dialog">
    <el-dialog title="欢迎登陆"
      :visible.sync="visible"
      :modal="false"
      :width="dialogWidth">
      <el-form :model="form"
        :rules="rules"
        ref="form"
        class="form">
        <el-form-item prop="account">
          <el-input v-model="form.account"
            maxlength="30"
            placeholder="账户"></el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input v-model="form.password"
            maxlength="16"
            type="password"
            placeholder="密码"></el-input>
        </el-form-item>
        <el-form-item prop="code">
          <div class="code">
            <el-input v-model="form.code"
              maxlength="4"
              placeholder="验证码"
              style="padding-right: 24px;"
              ></el-input>
              <img class="verify-code"
              :src="'data:image/jpeg;base64' + verifyCode" alt="验证码"
               @click="getVerifyCode()"
                @keydown="getVerifyCode()">
            <div class="code-picture"></div>
          </div>
        </el-form-item>
        <el-form-item>
          <div class="submit">
            <el-button type="primary"
            :loading="loading"
              @click="submitForm()">登陆</el-button>
          </div>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import userService from '@/services/user';
import AES from '@/utils/AES';

export default {
  name: 'LoginDialog',
  components: {},
  props: {
    type: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      dialogWidth: '30%',
      visible: false,
      form: {
        account: '',
        password: '',
        code: '',
      },
      rules: {
        account: [{ required: true, message: '请输入账户', trigger: 'blur' }],
        password: [{ required: true, message: '请输入密码', trigger: 'blur' }],
        code: [{ required: true, message: '请输入验证码', trigger: 'blur' }],
      },
      verifyCode: '',
      verifyCodeKey: '',
      loading: false,
    };
  },
  mounted() {
    window.onresize = () => (() => {
      this.setDialogWidth();
    })();
  },
  methods: {
    // 变更宽度
    setDialogWidth() {
      const val = document.body.clientWidth;
      if (val >= 975) {
        this.dialogWidth = '40%';
      } else if (val > 675 && val < 975) {
        this.dialogWidth = '60%';
      } if (val <= 675) {
        this.dialogWidth = '80%';
      }
    },
    getVerifyCode() {
      userService.getVerifyCode().then(({ data }) => {
        this.verifyCode = data.image;
        this.verifyCodeKey = data.key;
      });
    },
    showLoginDialog() {
      this.visible = true;
      this.getVerifyCode();
      this.form.account = '';
      this.form.password = '';
      this.form.code = '';
    },
    submitForm() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true;
          const form = {
            username: this.form.account,
            // eslint-disable-next-line prefer-destructuring
            password: AES.encrypt(this.form.password),
            key: this.verifyCodeKey,
            // eslint-disable-next-line prefer-destructuring
            code: this.form.code,
          };
          this.loading = true;
          this.$store.dispatch('user/login', form)
            .then(() => {
              this.loading = false;
              this.visible = false;
              this.$message.success('登录成功');
              if (this.type === 'normal') {
                this.$router.push({ path: '/user/comment' });
              }
            })
            .catch(() => {
              this.loading = false;
            });
          return true;
        }
        return false;
      });
    },
    // this.$refs.form.validate((valid) => {
    //   if (valid) {
    //     console.log('submit!');
    //     this.$alert('您已经连续5次登陆失败，账号将锁定5分钟；如忘记密码可联系管理员进行重置密码。', '警告', {
    //       type: 'error',
    //       confirmButtonText: '确定',
    //     });
    //   } else {
    //     console.log('error submit!!');
    //   }
    // });
  },
  created() {
    this.getVerifyCode();
    this.setDialogWidth();
  },
};
</script>

<style lang='scss' scoped>
.verify-code {
  height: 40px;
  right: 0;
  position: absolute;
}
.login-dialog {
  .el-dialog {
    max-width: 300px;
  }
  .form {
    .code {
      display: flex;
      align-items: center;
      .code-picture {
        width: 100px;
      }
    }
    .submit {
      width: 100%;
      .el-button {
        display: block;
        width: 100%;
      }
    }
  }
}
</style>
