import router from '@/router';
import store from '@/store';
import { getToken } from '@/utils/auth';
// import userUtils from '@/store/modules/user';

function addRoutes(routes) {
  routes.forEach((route) => {
    router.addRoute(route);
  });
}

router.beforeEach(async (to, from, next) => {
  router.app.$progress.start();
  const token = getToken();
  // eslint-disable-next-line no-constant-condition
  if (token) {
    // 判断用户是否已获取角色
    const hasRoles = store.state.user.roles && store.state.user.roles.length > 0;
    if (hasRoles) {
      next();
      router.app.$progress.done();
    } else {
      const { permissions } = await store.dispatch('user/getInfo');
      const accessRoutes = await store.dispatch('permission/generateRoutes', permissions);
      addRoutes(accessRoutes);
      next({ ...to, replace: true });
    }
  } else {
    next();
    router.app.$progress.done();
  }
  if (to.path === '/logout') {
    store.dispatch('user/logout');
    next({ path: '/' });
  }
});
