import userService from '@/services/user';
import {
  getToken,
  setToken,
  removeToken,
} from '@/utils/auth';

export default {
  state: {
    token: getToken(),
    roles: [],
    nickname: '',
    avatarUrl: '',
    userId: '',
    mobile: '',
    email: '',
    id: '',
    levelName: '',
    levelDescribe: '',
  },
  getters: {},
  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token;
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles;
    },
    SET_NICKNAME: (state, nickname) => {
      state.nickname = nickname;
    },
    SET_AVATARURL: (state, avatarUrl) => {
      state.avatarUrl = avatarUrl;
    },
    SET_USERID: (state, userId) => {
      state.userId = userId;
    },
    SET_MOBILE: (state, mobile) => {
      state.mobile = mobile;
    },
    SET_EMAIL: (state, email) => {
      state.email = email;
    },
    SET_ID: (state, id) => {
      state.id = id;
    },
    SET_LEVELNAME: (state, levelName) => {
      state.levelName = levelName;
    },
    SET_LEVELDESCRIBE: (state, levelDescribe) => {
      state.levelDescribe = levelDescribe;
    },
  },
  actions: {
    // user login
    login({
      commit,
    }, {
      username,
      password,
      key,
      code,
    }) {
      return new Promise((resolve, reject) => {
        userService
          .login({
            username,
            password,
            key,
            code,
          })
          .then((res) => {
            if (res) {
              const { data } = res;
              const [token] = data.split('|');
              setToken(token);
              commit('SET_TOKEN', token);
              resolve(); // 无需返回值
            } else {
              reject(res);
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    // get user info
    getInfo({ commit }) {
      return new Promise((resolve, reject) => {
        userService
          .getUserInfo()
          .then(async (res) => {
            if (res.code !== 200) {
              commit('SET_ROLES', []);
              resolve({ roles: [] });
              return;
            }
            const { data } = res;
            // commit('SET_ROLES', roles);
            commit('SET_ROLES', data.permissions);
            // 用户信息
            const info = await userService.getMemberlnfo();
            if (info.code === 200) {
              commit('SET_USERID', info.data.userId);
              commit('SET_NICKNAME', info.data.nickname);
              commit('SET_AVATARURL', info.data.avatarUrl);
              commit('SET_MOBILE', info.data.mobile);
              commit('SET_EMAIL', info.data.email);
              commit('SET_ID', info.data.id);
              commit('SET_LEVELNAME', info.data.levelInfo.levelName);
              commit('SET_LEVELDESCRIBE', info.data.levelInfo.levelDescribe);
            }

            resolve(data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    // user logout
    logout({
      commit,
    }) {
      commit('SET_TOKEN', '');
      commit('SET_ROLES', []);
      commit('SET_USERID', '');
      commit('SET_AVATARURL', '');
      commit('SET_MOBILE', '');
      commit('SET_EMAIL', '');
      commit('SET_ID', '');
      commit('SET_LEVELNAME', '');
      commit('SET_LEVELDESCRIBE', '');
      removeToken();
    },
  },
};
