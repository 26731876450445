import request from '@/utils/request';
import deepCopy from '@/utils/deepCopy';

export default {
  getUserInfo() {
    return request({
      url: '/front/user/currentUser',
      method: 'get',
    });
  },
  getVerifyCode() {
    return request({
      url: '/front/user/verifyCode',
      method: 'post',
    });
  },
  login(params) {
    const data = deepCopy(params);
    return request({
      url: '/front/user/login',
      method: 'post',
      params: data,
    });
  },
  getMemberlnfo() {
    return request({
      url: '/front/memberInfo/current',
      method: 'get',
    });
  },
  updateMemberlnfo(params) {
    const data = deepCopy(params);
    return request({
      url: '/front/memberInfo/edit',
      method: 'put',
      params: data,
    });
  },
  uploadHead() {
    return request({
      url: '/front/file/upload',
      method: 'post',
    });
  },
  // 获取用户评论
  getUserComment(params) {
    return request({
      url: '/front/articleComment/mine',
      method: 'get',
      params,
    });
  },
  // 修改密码
  modifyPassword(params) {
    return request({
      url: '/front/user/modifyPassword',
      method: 'post',
      data: params,
    });
  },
};
