<template>
  <div class="cq-menu cq-menu--default"
    :class="[`cq-menu--${mode}`]">
    <ul class="cq-menu--list">
      <!--   首页 顶栏   -->
      <cq-menu-item v-for="(lv1, lv1i) in data"
                    :key='lv1i' :data='lv1'
                    :router-link='routerLink'
                    :on-login='onLogin'/>

<!--      <li v-for="(lv1, lv1i) in data"-->
<!--        :key="lv1i"-->
<!--        class="cq-menu&#45;&#45;item">-->
<!--        <div class="cq-menu&#45;&#45;container"-->
<!--          :class="{'cq-accordion':mode === 'vertical'&& lv1.children
 && lv1.children.length > 0}">-->
<!--          <div v-if="mode === 'vertical' && lv1.children && lv1.children.length > 0"-->
<!--            class="cq-menu&#45;&#45;label cq-accordion&#45;&#45;lable is-alone">-->
<!--            <span class="cq-menu&#45;&#45;title">{{lv1.name}}</span>-->
<!--          </div>-->
<!--          <router-link v-else-if="lv1.name !== '个人中心'"-->
<!--            class="cq-menu&#45;&#45;label"-->
<!--            :to="routerLink(lv1, lv1i)">-->
<!--            <span class="cq-menu&#45;&#45;title">{{lv1.name}}</span>-->
<!--          </router-link>-->
<!--          <div v-else-->
<!--            class="cq-menu&#45;&#45;label"-->
<!--            @click="onLogin"-->
<!--            @keydown="onLogin">-->
<!--            <span class="cq-menu&#45;&#45;title">{{lv1.name}}</span>-->
<!--          </div>-->
<!--          &lt;!&ndash; 二级导航 &ndash;&gt;-->
<!--          <div v-if="lv1.children && lv1.children.length > 0"-->
<!--            class="cq-menu&#45;&#45;children is-level-2"-->
<!--            :class="{'cq-accordion&#45;&#45;content': mode === 'vertical'}">-->
<!--            <ul class="cq-menu&#45;&#45;list">-->
<!--              <li v-for="(lv2, lv2i) in lv1.children"-->
<!--                :key="lv2i"-->
<!--                class="cq-menu&#45;&#45;item">-->
<!--                <div class="cq-menu&#45;&#45;container">-->
<!--                  <router-link class="cq-menu&#45;&#45;label"-->
<!--                    :to="routerLink(lv2, lv2i)">-->
<!--                    <span class="cq-menu&#45;&#45;title">{{lv2.name}}</span>-->
<!--                  </router-link>-->
<!--                </div>-->
<!--              </li>-->
<!--            </ul>-->
<!--          </div>-->
<!--        </div>-->
<!--      </li>-->
    </ul>
  </div>
</template>

<script>
import CqMenuItem from '@/components/Front/Navigation/routerItem.vue';

export default {
  name: 'CQueryMenu',
  components: {
    CqMenuItem,
  },
  props: {
    // 模式
    mode: {
      type: String,
      default: 'horizontal',
    },
    data: {
      type: Array,
      default: () => ([]),
    },
    routerLink: {
      type: Function,
      default: () => '#',
    },
    onLogin: {
      type: Function,
      default: () => '',
    },
  },
  data() {
    return {};
  },
  methods: {},
  mounted() {
    this.$nextTick(() => {
      this.$cq.Accordion.setupDefault();
    });
  },
};
</script>

<style lang='scss' scoped>

</style>
