import navigationService from '@/services/navigation';

export default {
  state: {
    navigation: [],
  },
  getters: {
    // 搜索栏目
    findNavigation: (state) => (fun) => {
      let result = state.navigation.find(fun);
      if (!result) {
        state.navigation.forEach((item) => {
          const childrenResult = item.children.find(fun);
          if (childrenResult) result = childrenResult;
        });
      }
      return result;
    },
  },
  mutations: {
    SET_NAVIGATION: (state, navigation) => {
      // 递归处理嵌套路由
      const processChildren = (parent, children) => {
        children.forEach((child) => {
          // 设置父级引用
          // eslint-disable-next-line no-param-reassign
          child.parent = parent;
          // 如果存在子路由则继续递归处理
          if (child.children && child.children.length) {
            processChildren(child, child.children);
          }
        });
      };

      // 遍历顶级路由
      navigation.forEach((item) => {
        // 设置顶级路由的父级为null
        // eslint-disable-next-line no-param-reassign
        item.parent = null;
        // 处理子路由（如果有）
        if (item.children && item.children.length) {
          processChildren(item, item.children);
        }
      });
      state.navigation = navigation;
    },
  },
  actions: {
    getNavigation({ commit }) {
      navigationService.list().then(({ data }) => {
        commit('SET_NAVIGATION', data);
      });
    },
  },
};
