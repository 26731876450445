import axios from 'axios';
import Vue from 'vue';
import { Notification, MessageBox } from 'element-ui';
import { getToken } from '@/utils/auth';
import router from '@/router';
import store from '../store';

// create an axios instance
const request = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  timeout: 60 * 1000, // request timeout: 1 min
});

request.interceptors.request.use(
  (config) => {
    if (store.getters.token) {
      // eslint-disable-next-line no-param-reassign
      config.headers.Authorization = `Bearer ${getToken()}`; // 让每个请求携带自定义token 请根据实际情况自行修改
    }
    return config;
  },
  (error) => {
    console.log(error); // for debug
    Promise.reject(error);
  },
);

request.interceptors.response.use(
  (response) => {
    const res = response.data;
    if (res.code !== 200) {
      if (res.code === 401) {
        MessageBox.confirm('你已被登出，可以取消继续留在该页面，或者重新登录', '确定登出', {
          confirmButtonText: '重新登录',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(() => {
          store.dispatch('user/logout').then(() => {
            window.location.reload();// 为了重新实例化vue-router对象 避免bug
            router.push('/');
          });
        });
      } else if (res.code === 10100) {
        MessageBox.confirm('您没有权限阅读该文章，如有需要请联系管理员升级会员等级', {
          confirmButtonText: '确定',
          type: 'warning',
        }).then(() => {
          router.back();
        });
      } else {
        Notification.error({
          title: '错误',
          message: res.msg,
          duration: 2500,
        });
      }
      return Promise.reject(res);
    }
    return Promise.resolve(res);
  },
  (error) => {
    Vue.prototype.$message({
      message: error.message || '网络错误',
      type: 'error',
      duration: 5 * 1000,
    });
    return Promise.reject(error);
  },
);

export default request;
