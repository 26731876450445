<template>
  <!-- 整站网站页面容器(包裹整个网站，可用于统一网站页面背景等) -->
  <div class="page-container">
    <!-- 头部 -->
    <header>
      <front-nav />
    </header>
    <!-- 布局容器(放置页面内容，可用于统一页面内容最大宽度) -->
    <main>
      <slot v-if="$slots.default"></slot>
      <router-view v-else />
    </main>
    <!-- 底部 -->
    <front-footer />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import FrontNav from '@/components/Front/Navigation/index.vue';
import FrontFooter from '@/components/Front/Footer/index.vue';

export default {
  name: 'FrontLayout',
  components: {
    FrontNav,
    FrontFooter,
  },
  data() {
    return {};
  },
  methods: {
    ...mapActions('component', ['getNavigation']),
  },
  created() {
    this.getNavigation();
  },
};
</script>

<style lang='scss' scoped>
</style>
