<template>
  <li class="cq-menu--item">
    <div class="cq-menu--container"
         :class="{ 'cq-accordion': mode === 'vertical' && hasChildren }">
      <!-- 针对“个人中心”特殊处理 -->
      <template v-if="data.name === '个人中心'">
        <div class="cq-menu--label"
             @click="onLogin"
             @keydown="onLogin">
          <span class="cq-menu--title">{{ data.name }}</span>
        </div>
      </template>
      <!-- 没有子菜单时使用 router-link -->
      <router-link v-else class="cq-menu--label" :to="routerLink(data)">
        <span class="cq-menu--title">{{ data.name }}</span>
      </router-link>
      <!-- 普通菜单项 -->
<!--      <template v-else>-->
<!--        &lt;!&ndash; 如果存在子菜单 &ndash;&gt;-->
<!--        <div v-if="hasChildren" class="cq-menu&#45;&#45;label
 cq-accordion&#45;&#45;label is-alone">-->
<!--          <span class="cq-menu&#45;&#45;title">{{ data.name }}</span>-->
<!--        </div>-->
<!--        &lt;!&ndash; 没有子菜单时使用 router-link &ndash;&gt;-->
<!--        <router-link v-else class="cq-menu&#45;&#45;label" :to="routerLink(data)">-->
<!--          <span class="cq-menu&#45;&#45;title">{{ data.name }}</span>-->
<!--        </router-link>-->
<!--      </template>-->

      <!-- 递归渲染子级菜单 -->
      <div v-if="hasChildren"
           :style='childrenStyle'
           class="cq-menu--children is-level-2"
           :class="{ 'cq-accordion--content': mode === 'vertical' }">
        <ul class="cq-menu--list">
          <cq-menu-item v-for="(child, i) in data.children"
                        :deep-level='deepLevel+1'
                        :key="i"
                        :data="child"
                        :mode="mode"
                        :router-link='routerLink'
                     :on-login='onLogin' />
        </ul>
      </div>
    </div>
  </li>
</template>
<script>
export default {
  name: 'CqMenuItem',
  props: {
    // 模式
    mode: {
      type: String,
      default: 'horizontal',
    },
    data: {
      type: Object,
      default: () => ({}),
    },
    routerLink: {
      type: Function,
      default: () => '#',
    },
    onLogin: {
      type: Function,
      default: () => '',
    },
    deepLevel: {
      type: Number,
      default: 0,
    },
  },
  created() {
  },
  computed: {
    hasChildren() {
      return this.data.children && this.data.children.length > 0;
    },
    childrenStyle() {
      // style='left: 100%;top: 25%'
      if (this.deepLevel < 1) {
        return {};
      }
      return {
        left: '100%',
        top: '25%',
      };
    },
  },
};
</script>
<style scoped lang='scss'>
  .is-level-2 {
    --color-text: #333;
  }
</style>
